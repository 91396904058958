/* @tailwind base;
@tailwind components;
@tailwind utilities; */

* {
  margin: 0px;
  padding: 0px;
}
.create-div-height {
  height: 10px;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
.left-right-direction{
  direction: rtl;
}
textarea:focus,
input:focus {
  outline: none;
}

.customLoader {
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #5d71e1;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite; /* Safari */
}
.loaderOverlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
}
.loaderOverlay::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  top: 0;
  background-color: #00000060;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loginbackground {
  background-image: url('assets/images/loginbackground.png');

  background-color: black;
  background-repeat: no-repeat;

  object-fit: cover;
}

.logincard {
  /* box-shadow: -16px 19px 18px -8px rgba(255, 255, 255, 0.75);
  -webkit-box-shadow: -16px 19px 18px -8px rgba(255, 255, 255, 0.75);
  -moz-box-shadow: -16px 19px 18px -8px rgba(255, 255, 255, 0.75); */
  box-shadow: 0 0 26px 0px #fff;
  width: 80%;
}
@media (max-width: 600px) {
  .logincard {
    /* box-shadow: -16px 19px 18px -8px rgba(255, 255, 255, 0.75);
    -webkit-box-shadow: -16px 19px 18px -8px rgba(255, 255, 255, 0.75);
    -moz-box-shadow: -16px 19px 18px -8px rgba(255, 255, 255, 0.75); */
    box-shadow: 0 0 26px 0px #fff;
    width: 100%;
  }
}

.activity-button-styles {
  font-size: 10px;
  font-weight: 500;
  border-color: unset;
  color: white;
  min-width: 4rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activityBtn {
  margin-top: 0%;
}

.paraDiv {
  padding: 0rem 8rem 0rem 8rem;
  text-align: center;
  margin-top: 1rem;
}
.Logininputs {
  padding: 0rem 8rem 0rem 8rem;
  margin-top: 1rem;
  display: grid;
  gap: 1rem;
}
.Logininputs input {
  height: 3rem;
  padding: 1rem;
  border-radius: 0.2rem;
  border: 1px solid rgb(212, 212, 212);
}
.Logininputs input::placeholder {
  font-size: 0.8rem;
  font-weight: 200;
}
.loginOptionDiv {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 0.5rem;
}
.loginOptionDiv h3 {
  font-size: 0.9rem;
  margin-top: 0.5rem;
  opacity: calc(70%);
  font-weight: 600;
}
.loginOptionDiv img {
  height: 1.2rem;
  width: 1.2rem;
}
.LoginHavaccount {
  display: flex;
  gap: 1rem;
  margin-top: 4rem;
  padding-left: 4rem;
  align-items: center;
}
.LoginHavaccount h3 {
  font-size: 0.8rem;
  font-weight: 200;
}
@media (max-width: 600px) {
  .activityBtn {
    margin-top: -1.98rem;
  }
  .dogCol {
    display: none;
  }
  .loginbackground {
    padding: 0;
  }
  .paraDiv {
    padding: 0rem 1rem 0rem 1rem;
  }
  .Logininputs {
    padding: 0rem 2rem 0rem 2rem;
  }
}

@media (min-width: 100px) and (max-width: 500px) {
}

.icons-class {
  margin-left: 1.5rem;
}

.pets-button-styles {
  font-size: 8px;
  font-weight: 600;
  border-color: unset;
  color: white;
}

.circle-div-height {
  height: 100%;
}
@media (max-width: 763px) {
  .circle-div-height {
    height: 35vh;
  }
}
.circle {
  width: 10rem;
  height: 10rem;
}
@media (max-width: 600px) {
  .circle {
    width: 50%;
    height: 55%;
  }
}
.UniLinkinput-feild-width {
  width: 23rem;
  border-radius: 1rem;
  margin-left: 2rem;
  padding-left: 1rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
@media (max-width: 780px) {
  .UniLinkinput-feild-width {
    width: 100%;
    border-radius: 1rem;
    margin-left: 1rem;
    padding-left: 1rem;
    /* margin-top: 0.5rem; */
  }
}

.input-feild-width {
  width: 15rem;
  border-radius: 1rem;
  margin-left: 2rem;
  padding-left: 1rem;
}

@media (max-width: 1000px) {
  .input-feild-width {
    width: 90%;
    border-radius: 1rem;
    margin-left: 1rem;
    padding-left: 1rem;
    margin-top: 0.5rem;
  }
}
.pets_lastactivitybutton {
  display: flex;
}

@media (max-width: 763px) {
  .pets_lastactivitybutton {
    display: flex;
    justify-content: space-around;
  }
}
.petsButtonsList {
  display: flex;
}

@media (max-width: 763px) {
  .petsButtonsList {
    display: flex;
    flex-direction: column;
    margin-top: -0.5rem;
  }
}
.petsArrow {
  display: flex;
}

@media (max-width: 763px) {
  .petsArrow {
    display: flex;
    justify-content: end;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 1rem;
  }
}

.unlinkTagsStyle {
  display: flex;
  width: 100%;
}

@media (max-width: 763px) {
  .unlinkTagsStyle {
    display: flex;
    flex-direction: column;
  }
}
.unilinkTextContainer {
  display: flex;
}

@media (max-width: 763px) {
  .unilinkTextContainer {
    display: flex;
    flex-direction: column;
  }
}

.buttonBgColor1 {
  background-color: #41480e;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-left: 1.5rem;
  border-color: unset;
}

.buttonBgColor2 {
  background-color: #f477ab;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-left: 0.5rem;
}

.buttonBgColor3 {
  background-color: #6c60d3;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  margin-left: 0.5rem;
}

.buttonBgColor4 {
  background-color: #5cb47b;
  padding-left: 1.35rem;
  padding-right: 1.25rem;
  margin-left: 0.5rem;
}

@media (max-width: 1400px) {
  .buttonBgColor1 {
    padding: 0;
    width: 4.5rem;
  }
  .buttonBgColor2 {
    padding: 0;
    width: 4rem;
  }

  .buttonBgColor3 {
    padding: 0;
    width: 4.5rem;
  }

  .buttonBgColor4 {
    padding: 0;
    width: 4.8rem;
  }
}

.arrow {
  margin-left: 1rem;
}
.arrowDiv {
  padding: 2px;
  margin-right: 0;
}
@media (max-width: 763px) {
  .arrowDiv {
    padding: 8px;
    display: flex;
    justify-content: end;
    padding-left: 6px;
  }
}

.uniLinkText {
  font-weight: 700;
  font-size: large;
}
.text {
  font-weight: 700;
  font-size: large;
}

@media (max-width: 763px) {
  .uniLinkText {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
}
.unilinkArrow {
  display: flex;
}

@media (max-width: 763px) {
  .unilinkArrow {
    display: flex;
    justify-content: end;
    margin-top: -1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
}

@media (min-width: 100%) {
  .warningbuttondiv {
    display: flex;
    align-items: center;
    justify-content: end;
  }
}
.bgLinearGradiant {
  background-image: linear-gradient(#708ff4, #f09ac0, #f79f20);
}
.registerbglinearGardiant {
  background-image: linear-gradient(black, #101111, #373a38);
  height: 70vh;
}

.fullWidthInput {
  width: 100%;
}

.pets_header {
  display: flex;
  flex-direction: row;
}
@media (min-width: 100px) and (max-width: 1399px) {
  .pets_header {
    display: flex;
    flex-direction: column;
  }
}
.sendCodebtn {
  width: 8rem;
  margin-right: 8px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.uploadimageSize {
  display: flex;
  justify-content: center;
  height: 7rem;
  width: 7rem;
}

.imagecenter {
  display: flex;
  justify-content: center;
  flex-direction: column-reverse;
  align-items: center;
}

@media (min-width: 100px) and (max-width: 800px) {
  .imagecenter {
    display: flex;
    justify-content: flex-start;
  }
}

.registerpetimage {
  height: 11rem;
  width: 11rem;
}
@media (max-width: 763px) {
  .registerpetimage {
    height: 7rem;
    width: 7rem;
  }
}
.imageLabel {
  width: 6.5rem;
  margin-right: 8px;
}
.contactustag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 100px) and (max-width: 780px) {
  .contactustag {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
}
.tableText {
  font-size: x-small;
  font-weight: bold;
}
.timeStampText {
  font-size: 11px;
}
@media (max-width: 600px) {
  .timeStampText {
    font-size: 7px;
    font-weight: 600;
  }
}
.fontsize {
  font-size: 9px;
}

.dogImage {
  /* margin-left: 13.5rem; */
}

@media (min-width: 100px) and (max-width: 1416px) {
  .dogImage {
    /* margin-left: 4rem; */
  }
}
.footer {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 763px) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
}
.petfeildstyle {
  border: none;
  box-shadow: 0px 8px 8px 2px #e0e0e0;
  border-radius: 3rem;
}
.peteditform {
  width: 40%;
}
@media (min-width: 100px) and (max-width: 763px) {
  .peteditform {
    width: 100%;
  }
  /* .input-feild-width {
    max-width: 100%;
  } */
}
@media (min-width: 764px) and (max-width: 1024px) {
  .peteditform {
    width: 60%;
  }
}
.customClass::-webkit-scrollbar {
  display: none !important;
}
.modal-dialog-scrollable .modal-body::-webkit-scrollbar {
  display: none !important;
}

.ReactModal__Content {
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5) !important;
  height: 100%;
  width: 100%;
  margin-top: -2.4rem;
  margin-left: -2.5rem;

  border: none !important;
}
.ReactModal__Content::-webkit-scrollbar {
  display: none !important;
}
.ReactModal__Overlay {
  position: absolute !important;
}
.modalbackgroundcolor {
  background-color: #6c6c6c;
}
.locationmodalwidth {
  width: 38%;
  background-color: #212121;
  border-radius: 0.5rem;
}
@media (min-width: 100px) and (max-width: 600px) {
  .locationmodalwidth {
    width: 100%;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  .locationmodalwidth {
    width: 70%;
  }
}

@media (max-width: 1024px) {
  .loginDogImageContainer {
    display: none;
  }
}

.deletemodalwidth {
  width: 40%;
  background-color: white;
  border-radius: 1rem;
}

@media (min-width: 100px) and (max-width: 600px) {
  .deletemodalwidth {
    width: 100%;
  }
}
@media (min-width: 601px) and (max-width: 1024px) {
  .deletemodalwidth {
    width: 70%;
  }
}
.petfonts {
  font-size: 12px;
  font-weight: bold;
}
.leftpadding {
  padding-left: 8rem !important;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff;
  border: unset !important;
  border-radius: 2rem !important;
  line-height: 25px;
  height: 100% !important;
  width: 100% !important;
  outline: none;
}
.react-tel-input .selected-flag .arrow {
  position: relative;
  top: 50%;
  margin-top: 0 !important;
  left: 10px !important;
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #555;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: unset !important;
  border: unset !important;
  border-radius: 2rem !important;
  z-index: 99 !important;
}
.addToPeoplebtn {
  background-color: black;
  color: white;
  padding: 12px;
  border-radius: 0px 14px 14px 0;
}
.GetHopaAppbtn {
  background-color: black;
  color: white;
  padding: 12px;
  border-radius: 14px 0px 0px 14px;
}
.registerdiv1 {
  background-color: #ccd3cf;
  height: 30vh;
}
.registercontactbtn {
  height: 4rem;
  width: 4rem;
  padding: 3px;
  border-radius: 17px;
}
.btnmargincls {
  margin-left: 2rem;
}
.splashScreenposition {
  top: 26rem;
}
@media (max-width: 763px) {
  .splashScreenposition {
    top: 30rem;
  }
}
.splashIconsgap {
  margin-left: 2.5rem;
}

.form-select {
  width: 15% !important;
  background-color: #d8dbe0 !important;
  height: 3.5rem !important;
}
@media (max-width: 1024px) {
  .form-select {
    width: 30% !important;
    height: 3.5rem !important;
    background-color: #d8dbe0 !important;
  }
}
.was-validated .form-control:valid {
  background-image: none;
}
.was-validated .form-control:invalid {
  background-image: none;
}
.was-validated .form-select:valid:not([multiple]):not([size]) {
  background-image: none;
  padding-right: 0 !important;
}
.page-link {
  display: flex !important;
  color: none !important;
  border: none !important;
  background-color: unset !important;
}
.pagination-lg .page-link {
  padding: 0 !important;
}
.react-tel-input .form-control:focus {
  outline: none !important;
}
*:focus {
  outline: none !important;
}
.modal-header {
  padding: 10px !important;
}
.bordercolor {
  /* border-left-color: unset !important; */
  border-left: 0px !important;
  border-color: grey !important;
}
.btnColor {
  border-color: #5cb47b;
}

.ml-2 {
  margin-left: 0.5rem !important;
}
.dropdown-menu {
  min-width: 4rem !important;
  padding: 0.1rem 0 !important;
}

@font-face {
  font-family: 'RubikRegular';
  src: local('font'), url('assets/fonts/Rubik/static/Rubik-Regular.ttf') format('truetype');
}
.rubik-regular {
  font-family: 'RubikRegular';
}

.select__indicator-separator {
  display: none;
}
.select__indicators {
  display: none !important;
}

.select__control {
  min-height: 56px !important;
  border-radius: 1.2rem !important;
}

.react-select-container {
  width: 30%;
  margin-right: 0.5rem;
}
.select__menu {
  z-index: 100 !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  color: black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.px-10 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.rounded-switch > input {
  border-radius: 24px !important;
}

.error {
  color: #ec0000;
  text-align: left;
}
